import React, { useState, useEffect, useRef } from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import TableContainer from "../../../../UI/Dashboard/TableContainer";
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import JourneyAjax from '../../../../../helpers/JourneyAjax'
import Select from 'react-select'
import axios from "axios"
import { Link } from 'react-router-dom'

export default function RMDetails() {
  const navigate = useNavigate()
  const userValidToken = localStorage.getItem("userToken");
  const [RMDetails, setRmDetail] = useState(null)
  const [microMarketOption, setMicroMarketOption] = useState([])
  const [microRelationalManager, setMicroRelationalManager] = useState([])
  const [selectedMicroMarket, setSelectedMicroMarket] = useState([])
  const [rmMembers, setRmMembers] = useState([])
  const [searchName, setSearchName] = useState();
  const [rmtype, setRmType] = useState()
  const [rmName, setRmName] = useState()
  const [rmEmail, setRmEmail] = useState()
  const [rmMobile, setRmMobile] = useState()
  const [editMember, setEditMember] = useState(false)
  const [selectedManager, setSelectedManager] = useState()
  const microMarketNames = new Map();
  const urlParams = useParams()

  async function getRelationalManager() {
    try {
      const filter = {
        where: { or: [{ type: 'teamLead' }, { type: 'manager' }],status:1 }
      }
      const response = await axios.get(`${process.env.REACT_APP_JOURNEY_URL}/relation-managers?filter=${JSON.stringify(filter)}`)
      if (response.status === 200) {
        if (response?.data?.data?.relationManagers) {
          response.data.data.relationManagers = response?.data?.data?.relationManagers?.filter((relationManager) => {
            return relationManager.id !== urlParams.RM_Id
          })
          setMicroRelationalManager(response.data.data.relationManagers ?? [])
        }
      }
    } catch (err) {
      console.error(err.response.message)
    }
  }

  async function getMembers() {
    try {
      const filter = {
        where: { managerId: `${urlParams.RM_Id}`,status:1 }
      }
      const response = await axios.get(`${process.env.REACT_APP_JOURNEY_URL}/relation-managers?filter=${JSON.stringify(filter)}`)
      if (response.status === 200) {
        if (response?.data?.data?.relationManagers) {
          setRmMembers(response.data.data.relationManagers ?? [])
        }
      }
    } catch (err) {
      console.error(err.response.message)
    }
  }

  useEffect(() => {
    (async () => {
      getRelationalManager()
      const mmResponse = await axios.get(`${process.env.REACT_APP_CRM_URL}/micro-markets`)
      if (mmResponse.status === 200) {
        const microMarketList = []
        mmResponse?.data?.data?.map((item) => {
          microMarketNames.set(item?.id, item?.name)
          microMarketList.push({ label: item?.name, value: item?.id })
        })
        setMicroMarketOption(microMarketList)
      }
      const response = await JourneyAjax({
        url: `/relation-managers/${urlParams.RM_Id}`,
        "x-api-key": "houseeazy-dev",
        "x-user": userValidToken || null,
        token: true,
        loader: editMember ? true : false
      })
      if (response.status === 200) {
        setRmDetail(response.data)
        setRmType(response?.data?.type ?? "")
        setSelectedManager(response?.data?.managerId ?? "")
        setRmName(response?.data?.name ?? "")
        setRmEmail(response?.data?.email ?? "")
        setRmMobile(response?.data?.mobile ?? "")
        setEditMember(false)
        if (response?.data?.microMarket && response?.data?.microMarket.length > 0) {
          const micromarkets = []
          response?.data?.microMarket?.map((item) => {
            const name = microMarketNames.get(item)
            micromarkets.push({ label: name ?? "", value: item })
          })
          setSelectedMicroMarket(micromarkets)
        }
        if (response?.data?.type && response?.data?.type !== "member") {
          getMembers()
        }
      }
    })()
  }, [editMember])

  const updateRm = async (values, event) => {
    let microMarkets = []
     if(selectedMicroMarket && selectedMicroMarket.length > 0) {
      microMarkets = selectedMicroMarket?.map((item) => {
        return item.value
      })
    } else {
      toast.error('Micro market is required')
      return;
    }
    const data = {
      name: rmName,
      email: rmEmail,
      mobile: rmMobile,
      microMarket: microMarkets,
      type: rmtype,
      managerId: selectedManager
    }
    const update = await JourneyAjax({
      url: `/relation-managers/${urlParams.RM_Id}`,
      "x-api-key": "houseeazy-dev",
      "x-user": userValidToken || null,
      method: 'PATCH',
      data: data,
      token: true,
      loader: true
    })
    if (update.data.error) {
      toast.error(update.data.error.message)
    }
    if (update.status === 204) {
      toast.success('Relation Manager Updated Successfully')
      setTimeout(function () {
        navigate(`/admin/property-journey/relation-manager/${urlParams.RM_Id}`)
      }, 500)
    }
  }

  const rmFilter = async () =>{
    let filter = {}
    if(searchName !== ""){
       filter = {where:{managerId:urlParams.RM_Id,name:{like:searchName,options:"i"},status:1},order:["createdAt DESC"]}
    }else{
      filter = {where:{managerId:urlParams.RM_Id,status:1},order:["createdAt DESC"]}
    } 
    try {
      const response = await axios.get(`${process.env.REACT_APP_JOURNEY_URL}/relation-managers?filter=${JSON.stringify(filter)}`)
      if (response.status === 200) {
        if (response?.data?.data?.relationManagers) {
          setRmMembers(response.data.data.relationManagers ?? [])
        }
      }
    } catch (err) {
      console.error(err.response.message)
      toast.error('Filter error',{
        autoClose: 1000
      })
    } 
  }

  const handleMicroMarkets = (microMarket) => {
    if(Array.isArray(microMarket)){
      setSelectedMicroMarket([...microMarket])
    }else{
      setSelectedMicroMarket([microMarket])
    }
  }
  const handleManager = (e) => {
    setSelectedManager(e.target.value)
  }

  const deleteRecord = async (id) => {
    try{
      let confirm = window.confirm("Are you sure want to delete this record ?");
      if(confirm){
      await JourneyAjax({
        url: `/relation-managers/softDelete/${id}`,
        "x-api-key": "houseeazy-dev",
        "x-user": userValidToken,
        method:"DELETE",
        token: true,
        loader:true
      });
        toast.success('Relation manager deleted successfully',{
          autoClose: 1000
        })
        setTimeout(function(){
          window.location.reload()
        },500)
      }else{
        return false
      }
    }catch(error){
     console.log(error)
     toast.error('something went wrong',{
      autoClose: 1000
    })
    }
  }
  return (
    <ContentLayout  title="Edit Relation Manager">
      <Helmet>
        <title>Edit RM</title>
      </Helmet>
      <DataView title="Infomation">
        {RMDetails === null ? <DataLoader /> : <Formik
          initialValues={{
            name: RMDetails && RMDetails.name,
            email: RMDetails && RMDetails.email,
            mobile: RMDetails && RMDetails.mobile,
            microMarket: selectedMicroMarket
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string()
              .email("Invalid email")
              .required("Email is required"),
            mobile: Yup.string().required("Mobile Number is required")
            // microMarket: Yup.string().required("Micro market is required"),
          })}
          onSubmit={(values, event) => {
            updateRm(values, event)
          }}
        >
          <Form key={JSON.stringify(RMDetails)}>
            {rmtype !== "member" &&
              <ul className="nav nav-tabs">
                <li className="nav-item"><a href="#tab-rm" data-bs-toggle="tab" className="nav-link active">Relation Manager Info</a></li>
                <li className="nav-item"><a href="#tab-members" data-bs-toggle="tab" className="nav-link">Members</a></li>
              </ul>
            }
            <div className="tab-content">
              <div className="tab-pane active" id="tab-rm">
                <div className='row'>
                  <div className='col-md-6'>
                    <FormGroup label="Name" required>
                      <Field placeholder='Name' value={rmName} onChange={(e)=>setRmName(e.target.value)} name="name" className='form-control' />
                      <ErrorField><ErrorMessage name="name" /></ErrorField>
                    </FormGroup>
                  </div>

                  <div className='col-md-6'>
                    <FormGroup label="Email" required>
                      <Field placeholder='Email' value={rmEmail} onChange={(e)=>setRmEmail(e.target.value)} type="email" name="email" className='form-control' />
                      <ErrorField><ErrorMessage name="email" /></ErrorField>
                    </FormGroup>
                  </div>

                  <div className='col-md-6'>
                    <FormGroup label="Mobile" required>
                      <Field placeholder='Mobile Number' value={rmMobile} onChange={(e)=>setRmMobile(e.target.value)} type="string" name="mobile" className='form-control' />
                      <ErrorField><ErrorMessage name="mobile" /></ErrorField>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Micro Market" required>
                      <div className='col-md-12'>
                        {microMarketOption !== null ?
                          <Select
                            value={selectedMicroMarket}
                            options={microMarketOption}
                            onChange={handleMicroMarkets}
                            closeMenuOnSelect={false}
                            isMulti={rmtype !== "member" ? true : false}
                          /> : 'Loading...'}
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="type" required>
                      <select name="type" className='form-select' value={rmtype} onChange={(e)=>{
                        if(e.target.value){
                          setRmType(e.target.value)
                          setSelectedManager("")
                          setSelectedMicroMarket([])
                        }
                      }} >
                        <option value="" disabled>Select type</option>
                        {/* <option value="manager">Manager</option>
                        <option value="teamLead">Team lead</option>
                           <option value="member">Member</option> */}
                        {RMDetails.type == "member" && (
                          <>
                           <option value="teamLead">Team lead</option>
                           <option value="member">Member</option>
                           </>
                        )}
                        {RMDetails.type === "manager" && (
                          <option value="manager">Manager</option>
                        )}
                        {RMDetails.type === "teamLead" && (
                          <>
                          <option value="manager">Manager</option>
                          <option value="teamLead">Team Lead</option>
                          </>
                        )}
                      
                      </select>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Manager" >
                      {rmtype == "manager"  ? <select className='form-select' disabled></select> :
                        <select name="type" className='form-select' value={!selectedManager ? "None" : selectedManager} onChange={handleManager} >
                          <option value="" >None</option>
                          {microRelationalManager && microRelationalManager.map((data) => (
                            <>
                            {rmtype == "teamLead" && data.type == "manager" && 
                            <option key={data.id} value={data.id}>{data.name}</option>
                            }
                            {rmtype == "member" && data.type == "teamLead" && 
                            <option key={data.id} value={data.id}>{data.name}</option>
                            }
                            </>
                          ))}
                        </select>
                      }
                    </FormGroup>
                  </div>
                </div>
                <div className='col-md-2 d-grid'>
                  {/* <Button type="button">Update</Button> */}
                  <button type='button' onClick={updateRm}  className="btn btn-primary">update</button>
                </div>
              </div>
              <div className="tab-pane" id="tab-members" >
              { rmMembers &&  
              <div className='row'>
                  <div className='col-md-6'>
                    <FormGroup label="">
                      <input type="text" name="search" id="search" onKeyDown={(e) => {
                        if (e.key === "Enter")
                          rmFilter();
                        }}
                        onChange={(e) => setSearchName(e.target.value)} placeholder="Relation Mananger Members" className="form-control" />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button type='button' onClick={rmFilter} style={{ "paddingInline": "40px" }} className="btn btn-primary"><i className="fas fa-filter"></i> Filter</button>
                    </FormGroup>
                  </div>
               </div>
              }
                {RMDetails.type !== "member" &&
                  <TableContainer>
                    <thead>
                      <tr>
                        <th className="text-center">Sr No.</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Mobile</th>
                        <th className="text-center">Edit</th>
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rmMembers == null ? (
                        <TableLoader cols={6} />
                      ) : rmMembers.length === 0 ? (
                        <tr>
                          <td colSpan={6} className="text-center">
                            Data not available
                          </td>
                        </tr>
                      ) : (
                        rmMembers.map((data, index) => (
                          <tr key={data.id}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center text-capitalize">
                              {data.name}
                            </td>
                            <td className="text-center">{data.mobile}</td>
                            <td className="text-center">
                              {userValidToken ? (
                                <div >
                                   <Link to={`/admin/property-journey/relation-manager/${data.id}`} onClick={() => setEditMember(true)} title="" className="btn btn-primary"><i className="fas fa-pencil-alt"></i></Link>
                                </div>
                              ) : (
                                <div
                                  className="btn btn-primary"
                                >
                                  <i className="fas fa-pencil-alt" ></i>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              {userValidToken ? (
                                <button type="button" onClick={()=>deleteRecord(data.id)} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                              ) : (
                                <div
                                  className="btn btn-danger"
                                >
                                  <i className="fas fa-trash"></i>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </TableContainer>
                }
              </div>
            </div>
           
          </Form>
        </Formik>
        }
      </DataView>
    </ContentLayout>
  )
}
